import * as React from 'react';
import { Image, Group } from 'react-konva';
// @ts-ignore
import * as useImage from 'use-image';

import { ShapeType, GuidelinePosition } from '../../../interfaces/Canvas';
import { snapToGuideline, createGuideline } from '../../../util/canvas';
import ControlBox from '../ControlBox';

export interface ClipArtShapeData {
  type: ShapeType.CLIPART;

  /** Shape ID **/
  id: string;

  /** SVG string */
  svg: string;

  /** Shape Display Name **/
  display: string;

  /** Width of the shape */
  width: number;

  /** Height of the shape */
  height: number;

  /** Color of the shape */
  color: string;

  /** X position of the shape */
  x: number;

  /** Y position of the shape */
  y: number;

  /** Rotation of the shape */
  rotation: number;

  /** Vertical mirroring */
  flip: boolean;

  /** Horizontal mirroring */
  flop: boolean;

  /** zIndex */
  zIndex: number;
}

interface ClipArtShapeProps {
  shape: ClipArtShapeData;

  selected: boolean;

  mainPrintZone: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  /** Guideline Positions */
  guidelinePositions: GuidelinePosition[];

  /** Is resizeable */
  resizeable: boolean;

  /** Is draggable **/
  draggable: boolean;

  /** Flag to see if shape should snap to guidelines */
  snapToGuidelines: boolean;

  scale: {
    x: number;
    y: number;
  };

  /** Function executed when the image begins to be dragged */
  onDragStart: (shape: ClipArtShapeData) => void;

  /** Function executed when the image begins to be dragged */
  onDragMove: (shape: ClipArtShapeData, mousePosition: { x: number; y: number }) => void;

  /** Function executed when the image begins finishes to be dragged */
  onDragEnd: (shape: ClipArtShapeData) => void;

  /** Update handler */
  onUpdate: (shape: ClipArtShapeData) => void;

  /** Select handler */
  onSelect: (shape: ClipArtShapeData) => void;

  /** Mouse over handler */
  onMouseOver: (shape: ClipArtShapeData) => void;

  /** Mouse out handler */
  onMouseOut: (shape: ClipArtShapeData) => void;

  /** Resize start handler */
  onResizeStart: (shape: ClipArtShapeData) => void;

  /** Resize end handler */
  onResizeEnd: (shape: ClipArtShapeData) => void;

  /** Delete shape handler */
  onDeleteShape: (shape: ClipArtShapeData) => void;

  /** SVG image finished loading */
  onLoad?: (shape: ClipArtShapeData) => void;
}

/**
 * Parse svg string to;
 * 1. Replace all hex colors with new color
 * 2. Add inline style tag with fill color to handle cases where paths do not have a hex color, (svg defaults to black)
 **/
const replaceColor = (svg: string, hex: string) => {
  const inlineStyle = `<style>path {fill: ${hex}}</style>`;
  return svg.replace(/#[\da-f]{6}/gi, hex)
    .replace(/\<\/svg\>/gi, `${inlineStyle}</svg>`);
};

const ClipArtShape: React.FunctionComponent<ClipArtShapeProps> = (props, state) => {
  const {
    selected,
    draggable,
    resizeable,
    shape,
    mainPrintZone,
    guidelinePositions,
    scale,
    onDragStart,
    onDragMove,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onUpdate,
    onResizeStart,
    onResizeEnd,
    onDeleteShape,
    snapToGuidelines,
  } = props;

  const {
    x,
    y,
    width,
    height,
    color,
    id,
    svg,
    rotation,
    flip,
    flop,
  } = shape;

  React.useEffect(() => {
    props.onSelect(props.shape);
  }, []);
  const [blobUrls, setBlobUrls] = React.useState(state);

  const newSVG = replaceColor(svg, color);
  let imageUrl = blobUrls[newSVG];
  // Only create new blob urls when svg string has changed,
  // otherwise there will be an infinite loop of rerenders.
  if (!imageUrl) {
    const blob = new Blob([newSVG], { type: 'image/svg+xml' });
    imageUrl = URL.createObjectURL(blob);
    setBlobUrls((prevBlobUrls: any) => {
      const newBlobUrls = Object.assign({}, prevBlobUrls);
      newBlobUrls[newSVG] = imageUrl;
      return newBlobUrls;
    });
  }
  const [image, imageStatus] = useImage(imageUrl);
  React.useEffect(() => {
    // imageStatus is either loaded or failed, both of which should trigger onLoad
    if (imageStatus === 'loading') { return; }
    if (props.onLoad) { props.onLoad(props.shape); }
  }, [imageStatus]);

  return (
    <Group
      name={id}
      x={x}
      y={y}
      draggable={draggable}
      onClick={(event) => {
        event.cancelBubble = true;
        props.onSelect(shape);
      }}
      onTap={(event) => {
        event.cancelBubble = true;
        props.onSelect(shape);
      }}
      onDragStart={() => onDragStart(shape)}
      onDragMove={(event) => {
        const mousePosition = {
          x: event.target.attrs.x + shape.width / 2,
          y: event.target.attrs.y + shape.height / 2,
        };
        const draggedShape = Object.assign({}, shape, {
          x: event.target.attrs.x,
          y: event.target.attrs.y
        });

        onDragMove(draggedShape, mousePosition);
      }}
      onDragEnd={(event) => {
        let newShape = Object.assign({}, shape, {
          x: event.target.attrs.x,
          y: event.target.attrs.y,
        });
        if (snapToGuidelines) {
          const mousePosition = {
            x: event.target.attrs.x + shape.width / 2,
            y: event.target.attrs.y + shape.height / 2,
          };
          const mainGuideline = createGuideline(mainPrintZone, guidelinePositions[0]);
          const snapData = snapToGuideline(newShape, mousePosition, mainGuideline);
          if (snapData.isSnappable) {
            newShape = Object.assign({}, shape, snapData.shape);
          }
        }
        onUpdate(newShape);
        onDragEnd(shape);
      }}
      onMouseOver={() => onMouseOver(shape)}
      onMouseOut={() => onMouseOut(shape)}
    >
      <Image
        width={width}
        height={height}
        image={image}
        scaleX={flop ? -1 : 1}
        scaleY={flip ? -1 : 1}
        x={width / 2}
        y={height / 2}
        offsetX={width / 2}
        offsetY={height / 2}
        rotation={rotation * (flip ? -1 : 1)}
      />
      <ControlBox
        selected={selected ? shape : undefined}
        onUpdateShape={onUpdate}
        onResizeStart={onResizeStart}
        onResizeEnd={onResizeEnd}
        mainPrintZone={mainPrintZone}
        onDeleteShape={onDeleteShape}
        resizable={resizeable}
        scale={scale}
      />
    </Group>
  );

};

export default ClipArtShape;
