import * as React from 'react';
import { Stage, Layer, Image } from 'react-konva';
import { Stage as StageNode } from 'konva/types/Stage';

// @ts-ignore
import * as useImage from 'use-image';
const konva = require('react-konva');

import { isTouchSupported } from '../../util/touch';

import PrintZone, { PrintZoneProps, DEFAULT_PRINTZONE_PROPS } from './PrintZone';
import Guideline from './PrintZone/Guideline';
import TextShape from './TextShape';
import ImageShape from './ImageShape';
import ClipArtShape from './ClipArtShape';
import HoverBox from './HoverBox';
import { Shape, ShapeType, GuidelinePosition } from '../../interfaces/Canvas';

export interface CanvasProps {
  /** Width of the canvas */
  width: number;

  /** Height of the canvas */
  height: number;

  /** Scale of the canvas */
  scale: {
    x: number;
    y: number;
  };

  /** Shape selected */
  selectedId: string | null;

  /** Shape selected */
  hoveredId: string | null;

  /** Array of elements */
  shapes: Shape[];

  /** Print area where the elements must be displayed */
  printArea: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  /** Element removal handler */
  onDeleteShape: (shape: Shape) => void;

  /** Element update handler */
  onUpdateShape: (shape: Shape) => void;

  /** Element update handler */
  onSelectShape: (shape: Shape) => void;

  /** Deselect handler **/
  onDeselect: () => void;

  /** Source of the blank image */
  blankSrc: string | null;

  /** Width of the blank image */
  blankWidth: number;

  /** Height of the blank image*/
  blankHeight: number;

  /** Inc per Pixel */
  inchPerPx: number;

  /** Print Zones data */
  printZones: PrintZoneProps[];

  /** Main Print Zones data */
  mainPrintZone: PrintZoneProps;

  guidelinePositions: GuidelinePosition[];

  /** Show print zone outlines (i.e. print zones) **/
  showPrintZones: boolean;

  /** Print zone properties (ie. color) */
  printZoneProps: {
    color: string;
  };

  /** Guideline properties (ie. color) */
  guidelineProps: {
    showVerticalGuide: boolean;
    color?: string;
  }

  /** Drag start handler */
  onDragStart: (shape: Shape) => void;

  /** Drag move handler */
  onDragMove: (shape: Shape, mousePosition: { x: number; y: number }) => void;

  /** Drag end handler */
  onDragEnd: (shape: Shape) => void;

  /** Mouse over handler */
  onMouseOver: (shape: Shape) => void;

  /** Mouse out handler */
  onMouseOut: (shape: Shape) => void;

  /** Drag start handler */
  onResizeStart: (shape: Shape) => void;

  /** Drag end handler */
  onResizeEnd: (shape: Shape) => void;

  /** When Konva stage ref available. Used for artwork rendering */
  onRef?: (ref: StageNode) => void;

  /** All shapes in Canvas have loaded */
  onShapeLoad?: (shape: Shape) => void;
}

const BlankImage: React.FunctionComponent<{ blankSrc: string, width: number, height: number }> = ({
  blankSrc,
  width,
  height,
}) => {
  const [image] = useImage(blankSrc);
  return (
    <Image
      namge="blank-0"
      draggable={false}
      image={image}
      width={width}
      height={height}
      x={0}
      y={0}
    />
  );
};

export const PRINTZONE_COLOR_HEX = {
  default: DEFAULT_PRINTZONE_PROPS.color,
  warning: '#FFB701',
};

export default class Canvas extends React.Component<CanvasProps, { mouseInBounds: boolean }> {
  stageRef: StageNode | null;

  state = {
    mouseInBounds: false,
  };

  componentDidMount() {
    konva.useStrictMode(true);
    if (this.props.onRef && this.stageRef) { this.props.onRef(this.stageRef); }
    if (isTouchSupported()) {
      this.setState({ mouseInBounds: true });
    }
  }

  render() {
    const {
      selectedId,
      hoveredId,
      blankSrc,
      width,
      height,
      scale,
      blankWidth,
      blankHeight,
      printZones,
      showPrintZones,
      printZoneProps,
      guidelineProps,
      mainPrintZone,
      guidelinePositions,
      shapes,
    } = this.props;

    const selected = shapes.find(({ id }) => id === selectedId);
    const hovered = hoveredId === selectedId ? undefined : shapes.find(({ id }) => id === hoveredId);

    return (
      <div
        onMouseOver={() => this.setState({ mouseInBounds: true })}
        onMouseLeave={() => this.setState({ mouseInBounds: false })}
      >
        <Stage
          width={width}
          height={height}
          scale={scale}
          onClick={this.props.onDeselect}
          onTap={this.props.onDeselect}
          ref={(ref: any) => { this.stageRef = ref; }}
        >
          <Layer>
            {blankSrc && <BlankImage blankSrc={blankSrc} width={blankWidth} height={blankHeight} />}
            {showPrintZones && printZones.map((printZone, i) => <PrintZone key={i} {...printZone} {...printZoneProps}/>)}
          </Layer>
          <Layer x={mainPrintZone.x} y={mainPrintZone.y}>
            {shapes.map((shape) => {
              if (shape.type === ShapeType.TEXT) {
                return <TextShape
                  key={shape.id}
                  shape={shape}
                  scale={scale}
                  selected={shape.id === selectedId}
                  draggable={this.state.mouseInBounds}
                  resizeable={this.state.mouseInBounds}
                  snapToGuidelines={true}
                  mainPrintZone={mainPrintZone}
                  guidelinePositions={guidelinePositions}

                  onUpdate={this.props.onUpdateShape}
                  onDragStart={this.props.onDragStart}
                  onDragMove={this.props.onDragMove}
                  onDragEnd={this.props.onDragEnd}
                  onMouseOver={this.props.onMouseOver}
                  onMouseOut={this.props.onMouseOut}
                  onLoad={this.props.onShapeLoad}
                  onSelect={this.props.onSelectShape}
                  onResizeStart={this.props.onResizeStart}
                  onResizeEnd={this.props.onResizeEnd}
                  onDeleteShape={this.props.onDeleteShape}
                />;
              }

              if (shape.type === ShapeType.IMAGE) {
                return <ImageShape
                  key={shape.id}
                  shape={shape}
                  scale={scale}
                  selected={shape.id === selectedId}
                  draggable={this.state.mouseInBounds}
                  resizeable={this.state.mouseInBounds}
                  snapToGuidelines={true}
                  mainPrintZone={mainPrintZone}
                  guidelinePositions={guidelinePositions}

                  onUpdate={this.props.onUpdateShape}
                  onDragStart={this.props.onDragStart}
                  onDragMove={this.props.onDragMove}
                  onDragEnd={this.props.onDragEnd}
                  onMouseOver={this.props.onMouseOver}
                  onMouseOut={this.props.onMouseOut}
                  onSelect={this.props.onSelectShape}
                  onResizeStart={this.props.onResizeStart}
                  onResizeEnd={this.props.onResizeEnd}
                  onDeleteShape={this.props.onDeleteShape}
                  onLoad={this.props.onShapeLoad}
                />;
              }

              if (shape.type === ShapeType.CLIPART) {
                return <ClipArtShape
                  key={shape.id}
                  shape={shape}
                  scale={scale}
                  selected={shape.id === selectedId}
                  draggable={this.state.mouseInBounds}
                  resizeable={this.state.mouseInBounds}
                  snapToGuidelines={true}
                  mainPrintZone={mainPrintZone}
                  guidelinePositions={guidelinePositions}

                  onUpdate={this.props.onUpdateShape}
                  onDragStart={this.props.onDragStart}
                  onDragMove={this.props.onDragMove}
                  onDragEnd={this.props.onDragEnd}
                  onMouseOver={this.props.onMouseOver}
                  onMouseOut={this.props.onMouseOut}
                  onSelect={this.props.onSelectShape}
                  onResizeStart={this.props.onResizeStart}
                  onResizeEnd={this.props.onResizeEnd}
                  onDeleteShape={this.props.onDeleteShape}
                  onLoad={this.props.onShapeLoad}
                />;
              }
            })}
            <HoverBox
              hovered={hovered || undefined}
            />
          </Layer>
          <Layer>
            {guidelineProps && guidelineProps.showVerticalGuide &&
              guidelinePositions.map((position) => <Guideline key={position} position={position} {...mainPrintZone} {...printZoneProps} {...guidelineProps} color={guidelineProps.color} />)
            }
          </Layer>
        </Stage>
      </div>
    );
  }
}
