/*

This page is responsible for rendering composed artwork from design tool.
inkp-mockup will visit this page with Puppeteer (headless Chrome) then call toDataURL
on the Konva stage in order to generate an artwork PNG.

To ensure assets used in design are fully loaded we employe these techniques:
- The Canvas component will set _KONVA_REF_ on `window` as the Konva ref
- When window.onload has happened and _KONVA_REF_ is present, toDataURL is called.
At this time, if any fonts or images are not fully loaded by browser, the composed
artwork will be missing them. Thus we must guarantee they are loaded
- Each shape component (currently text, clipart, image) implements an onLoad callback
- All fonts used in design are loaded at first render in addition, becuause TextShape
  onLoad does not cover external font loading

Since this page reuses the Canvas component, when new Shape features are added
they should be able to work in artwork composition with minimal effort. Here
are the steps still necessary
- Implement onLoad in your new shape
- Ensure Canvas is calling onShapeLoad when the shape's onLoad is trigger
- Ensure the GraphQL query DESIGN_QUERY is updated to pull relevant fields from shapes

*/

import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import Canvas from '../../components/Canvas';
import { Shape } from '../../interfaces/Canvas';

// @ts-ignore
import CustomFont from 'inkp-components/dist/Components/CustomFont';
import { PrintZone } from 'inkp-product-sdk/types.g';
import { Design, DesignSide, SHAPE_TYPE_ENUM } from 'inkp-design-sdk/types.g';
import { Stage } from 'konva/types/Stage';
import { FontsByNamesQuery, FONTS_BY_NAMES_QUERY } from '../DesignTool/FontSelector';
import { RouteComponentProps } from 'react-router';

const FULL_CANVAS_WIDTH = 900;
const FULL_CANVAS_HEIGHT = 1125;

// TODO: Should this use a design fragment?
export const DESIGN_QUERY = gql`
  query DesignById($id: String!) {
    designById(id: $id) {
      id
      sides {
        name
        printZoneId
        printZone {
          name
          position {
            top
            bottom
            right
            left
          }
          size {
            width
            height
          }
        }
        inchPerPx
        shapes {
          id
          type
          display
          x
          y
          width
          height
          rotation
          zIndex

          src
          svg
          artworkId
          naturalWidth
          naturalHeight
          flip
          flop

          align
          color
          text
          fontFamily
          fontSize
        }
      }
    }
  }
`;

interface DesignRenderPageParams {
  id: string;
  side: string;
}
interface PageState {
  loaded: any[];
  ref: any;
  adjustedCanvasWidth?: number;
  adjustedCanvasHeight?: number;
}

export default class DesignRender extends React.Component<RouteComponentProps<DesignRenderPageParams>, PageState> {
  state: PageState = { loaded: [], ref: null, adjustedCanvasWidth: undefined, adjustedCanvasHeight: undefined };

  shapesLoaded: any[] = [];
  konvaRef: Stage | null = null;

  expandCanvasArea = () => {
    if (this.state.adjustedCanvasHeight && this.state.adjustedCanvasWidth) {
      this.setState({ adjustedCanvasWidth: undefined, adjustedCanvasHeight: undefined });
    } else {
      this.setState({ adjustedCanvasWidth: FULL_CANVAS_WIDTH, adjustedCanvasHeight: FULL_CANVAS_HEIGHT });
    }
  };

  renderDesign(design: Design, sideName: string) {
    const designSide = design.sides.find((side: DesignSide) => side.name === sideName);

    if (!designSide) {
      console.error(`Could not find side ${sideName} on ${design.id}`);
      return null;
    }

    const printZone: PrintZone = designSide.printZone as PrintZone;
    const printZoneWidth = Math.round(printZone.size.width / designSide.inchPerPx);
    const printZoneHeight = Math.round(printZone.size.height / designSide.inchPerPx);

    let canvasWidth = printZoneWidth;
    let canvasHeight = printZoneHeight;
    let printZoneX = 0;
    let printZoneY = 0;
    if (this.state.adjustedCanvasWidth && this.state.adjustedCanvasHeight) {
      canvasWidth = this.state.adjustedCanvasWidth;
      canvasHeight = this.state.adjustedCanvasHeight;
      printZoneX = canvasWidth / 2 - printZoneWidth / 2;
      printZoneY = canvasHeight / 2 - printZoneHeight / 2;
    }

    const printArea = {
      name: 'Render',
      x: printZoneX,
      y: printZoneY,
      width: printZoneWidth,
      height: printZoneHeight,
      main: false,
      color: '#000000',
    };

    const noop = () => {};

    const fontFamilies: string[] = [];
    for (const shape of designSide.shapes) {
      if (
        shape.type === SHAPE_TYPE_ENUM.TEXT &&
        shape.fontFamily &&
        fontFamilies.indexOf(shape.fontFamily) === -1 // Ensure no duplicates
      ) {
        fontFamilies.push(shape.fontFamily);
      }
    }

    return (
      <FontsByNamesQuery query={FONTS_BY_NAMES_QUERY} variables={{ fontNames: fontFamilies }}>
        {({ data: fontData = { fontsByNames: [] }, error: fontError, loading: fontLoading }) => {
          return (
            <div className="col col-2">
              {/* Ensure fonts are loaded */}
              {fontData.fontsByNames.map((font) => {
                return <CustomFont key={font.name} name={font.name} url={font.url} />;
              })}

              <div className="w-full p-relative" id="canvas-container">
                <Canvas
                  selectedId={null}
                  // @ts-ignore: Enum cannot be coereced even if matching
                  shapes={designSide.shapes}
                  blankSrc={null}
                  width={canvasWidth}
                  height={canvasHeight}
                  scale={{
                    x: 1,
                    y: 1,
                  }}
                  printArea={printArea}
                  mainPrintZone={printArea}
                  inchPerPx={designSide.inchPerPx}
                  showPrintZones={false}
                  onDeleteShape={noop}
                  onUpdateShape={noop}
                  onDragStart={noop}
                  onDragEnd={noop}
                  onResizeStart={noop}
                  onResizeEnd={noop}
                  onSelectShape={noop}
                  onDeselect={noop}
                  onMouseOver={noop}
                  onMouseOut={noop}
                  onShapeLoad={(shape: Shape) => {
                    this.shapesLoaded.push(shape);
                    this.completeLoad(designSide.shapes);
                  }}
                  onRef={(ref) => {
                    this.konvaRef = ref;
                    this.completeLoad(designSide.shapes);
                  }}
                />
              </div>
            </div>
          );
        }}
      </FontsByNamesQuery>
    );
  }

  completeLoad(requiredShapes: any[]) {
    // Sets ref onto global Window object for puppeteer to access
    for (const shape of requiredShapes) {
      // Ensure onShapeLoad has run for each shape
      if (!this.shapesLoaded.includes(shape)) {
        return;
      }
    }
    if (!this.konvaRef) {
      // Ref not ready
      return;
    }
    (global as any)._KONVA_REF_ = this.konvaRef;
    // puppeteer will be polling for this to be set, and then use it to take artwork render
  }

  render() {
    const sideName = this.props.match.params.side;
    const designId = this.props.match.params.id;

    return (
      <Query query={DESIGN_QUERY} variables={{ id: designId }}>
        {({ loading, error, data: { designById } }: any) => {
          if (loading) return null;
          if (error) {
            console.error(error);
            return null;
          }
          if (!designById) {
            console.error('Could not find design', designId);
            return null;
          }
          return (
            <React.Fragment>
              {this.renderDesign(designById, sideName)}
              <button className="d-b bw-1 p-1" onClick={this.expandCanvasArea}>
                <span className="d-b p-relative">Full print area</span>
              </button>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
