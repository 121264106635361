import React from 'react';
import * as _ from 'lodash';

import ToggleButton from 'inkp-components/dist/Components/ToggleButton';
import ListToggle from 'inkp-components/dist/HOC/ListToggle';

import { FontCategory } from '../../../../interfaces';
import { DEFAULT_FONT_CATEGORY } from '../../../../util/canvas';

interface CategoryObject extends FontCategory {
  active?: boolean;
}

interface Props {
  /**
   * list of category objects
   * @type CategoryObject[]
   */
  categories: CategoryObject[];

  /**
   * What to do with clicked font object
   * @type (categoryObject: CategoryObject) => void;
   */
  onCategorySelect: (categoryObject: CategoryObject) => void;

  /**
   * state of 'Categories' dropdown
   * @type boolean
   */
  listOpen?: boolean;

  /**
   * function for toggling list categories list open/closed
   * @type () => void
   */

  toggleListOpen: () => void;
}

const FontSelector: React.FunctionComponent<Props> = (props) => {
  const { categories, onCategorySelect, toggleListOpen, listOpen } = props;
  const activeCategory = categories.find(({ active }) => !!active);
  return (
    <div className="flex flex-col mx-1 my-p75">
      <ListToggle label="Categories" onClick={toggleListOpen} open={listOpen}>
        <div className="flex flex-wrap -mx-p5">
          {categories.map(({ active, name }) => (
            <div key={name} className="h-3 my-p5 w-1/3 px-p5" onClick={() => onCategorySelect({ name })}>
              <ToggleButton text={_.capitalize(name)} active={active} />
            </div>
          ))}
        </div>
      </ListToggle>
      <div className="flex justify-center bgc-gray-50 mt-1 -mx-1">
        <div className="my-p5 fs-lg fw-bold">{_.capitalize((activeCategory || DEFAULT_FONT_CATEGORY).name)}</div>
      </div>
    </div>
  );
};

export default FontSelector;
