import * as React from 'react';
import { Image, Group } from 'react-konva';
// @ts-ignore
import * as useImage from 'use-image';

import { rotatedPosition } from 'inkp-design-sdk/geometry';
import { ImageShapeProps } from '../../../interfaces/Canvas';
import { snapToGuideline, createGuideline } from '../../../util/canvas';
import ControlBox from '../ControlBox';

export const MIN_QUALITY_DPI = 150;

const ImageShape: React.FunctionComponent<ImageShapeProps> = (props) => {
  const {
    selected,
    draggable,
    resizeable,
    shape,
    mainPrintZone,
    guidelinePositions,
    scale,
    onDragStart,
    onDragMove,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onUpdate,
    onResizeStart,
    onResizeEnd,
    onDeleteShape,
    snapToGuidelines,
  } = props;

  const {
    x,
    y,
    width,
    height,
    id,
    src,
    rotation,
    flip,
    flop,
  } = shape;

  const [image, imageStatus] = useImage(src);
  React.useEffect(() => {
    // imageStatus is either loaded or failed, both of which should trigger onLoad
    if (imageStatus === 'loading') { return; }
    if (props.onLoad) { props.onLoad(props.shape); }
  }, [imageStatus]);

  React.useEffect(() => {
    props.onSelect(props.shape);
  }, []);

  const rPosition = rotatedPosition(shape);

  return (
    <Group
      name={id}
      x={x}
      y={y}
      draggable={draggable}
      onClick={(event) => {
        event.cancelBubble = true;
        props.onSelect(shape);
      }}
      onTap={(event) => {
        event.cancelBubble = true;
        props.onSelect(shape);
      }}
      onDragStart={() => onDragStart(shape)}
      onDragMove={(event) => {
        const mousePosition = {
          x: event.target.attrs.x + shape.width / 2,
          y: event.target.attrs.y + shape.height / 2,
        };
        const draggedShape = Object.assign({}, shape, {
          x: event.target.attrs.x,
          y: event.target.attrs.y
        });

        onDragMove(draggedShape, mousePosition);
      }}
      onDragEnd={(event) => {
        let newShape = Object.assign({}, shape, {
          x: event.target.attrs.x,
          y: event.target.attrs.y,
        });
        if (snapToGuidelines) {
          const mousePosition = {
            x: event.target.attrs.x + shape.width / 2,
            y: event.target.attrs.y + shape.height / 2,
          };
          const mainGuideline = createGuideline(props.mainPrintZone, props.guidelinePositions[0]);
          const snapData = snapToGuideline(newShape, mousePosition, mainGuideline);
          if (snapData.isSnappable) {
            newShape = Object.assign({}, shape, snapData.shape);
          }
        }
        onUpdate(newShape);
        onDragEnd(shape);
      }}
      onMouseOver={() => onMouseOver(shape)}
      onMouseOut={() => onMouseOut(shape)}
    >
      <Image
        width={width}
        height={height}
        image={image}
        scaleX={flop ? -1 : 1}
        scaleY={flip ? -1 : 1}
        x={width / 2}
        y={height / 2}
        offsetX={width / 2}
        offsetY={height / 2}
        rotation={rotation * (flip ? -1 : 1)}
      />
      <ControlBox
        selected={selected ? shape : undefined}
        onUpdateShape={onUpdate}
        onResizeStart={onResizeStart}
        onResizeEnd={onResizeEnd}
        mainPrintZone={mainPrintZone}
        onDeleteShape={onDeleteShape}
        resizable={resizeable}
        scale={scale}
      />
    </Group>
  );
};

export default ImageShape;

        // dragBoundFunc={(pos: { x: number; y: number }) => {
        //   if (!width) return { x: pos.x, y: pos.y };
        //   let newX = Math.max((mainPrintZone.x * scale.x) + (width * scale.x / 2) - (rPosition.x - (x || 0)), pos.x);
        //   let newY = Math.max((mainPrintZone.y * scale.y) + (height * scale.y / 2) - (rPosition.y - (y || 0)), pos.y);

        //   newX = Math.min((mainPrintZone.x * scale.x) + (mainPrintZone.width * scale.x) - (width * scale.x / 2) + (rPosition.x - (x || 0)), newX);
        //   newY = Math.min((mainPrintZone.y * scale.y) + (mainPrintZone.height * scale.y) - (height * scale.y / 2) + (rPosition.y - (y || 0)), newY);
        //   return { x: newX, y: newY };
        // }}
