import React from 'react';

import FontListItem from 'inkp-components/dist/Components/FontListItem';

import { Font } from '../../../../interfaces';

interface FontObject extends Font {
  active?: boolean;
}

interface Props {
  /**
   * list of font objects
   * @type FontObject[]
   */
  fonts: FontObject[];

  /**
   * What to do with clicked font object
   * @type (fontObject: FontObject) => void;
   */
  onFontSelect: (fontObject: FontObject) => void;
}

const FontSelector: React.FunctionComponent<Props> = (props) => {
  const { fonts, onFontSelect } = props;
  return (
    <div className="flex flex-col">
      <div className="flex-1 overflow-y-auto mx-1 lg:mx-2">
        <div className="color-gray-400 mt-1p5">{`Showing ${fonts.length} Fonts`}</div>
        {fonts.map((font) => (
          <div key={font.name} className="mt-1 cursor-pointer" onClick={() => onFontSelect(font)}>
            <FontListItem {...font} key={font.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FontSelector;
