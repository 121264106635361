import * as React from 'react';
import { Text, Group } from 'react-konva';

import { TextShapeData, GuidelinePosition } from '../../../interfaces/Canvas';
import { LINE_HEIGHT, NEW_LINE_CHAR, normalizeText, computeFontSize, snapToGuideline, createGuideline } from '../../../util/canvas';
import ControlBox from '../ControlBox';

export interface TextShapeProps {
  shape: TextShapeData;

  selected?: boolean;

  mainPrintZone: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  /** Guideline Positions */
  guidelinePositions: GuidelinePosition[];

  /** Is resizeable */
  resizeable: boolean;

  /** Is draggable **/
  draggable: boolean

  /** Flag to allow shape to snap to guidelines */
  snapToGuidelines: boolean;

  scale: {
    x: number;
    y: number;
  }

  /** Function executed when the text dragging starts, on mousedown and move */
  onDragStart: (shape: TextShapeData) => void;

  /** Function executed when the text is currently being dragged, on mousemove */
  onDragMove: (shape: TextShapeData, mousePosition: { x: number; y: number }) => void;

  /** Function executed when the text dragging ends, on mouseup */
  onDragEnd: (shape: TextShapeData) => void;

  /** Update handler */
  onUpdate: (shape: TextShapeData) => void;

  /** Select handler */
  onSelect: (shape: TextShapeData) => void;

  /** Mouse over handler */
  onMouseOver: (shape: TextShapeData) => void;

  /** Mouse out handler */
  onMouseOut: (shape: TextShapeData) => void;

  /** Resize start handler */
  onResizeStart: (shape: TextShapeData) => void;

  /** Resize end handler */
  onResizeEnd: (shape: TextShapeData) => void;

  /** Delete shape handler */
  onDeleteShape: (shape: TextShapeData) => void;

  /**
   * Font finished loading and is renderable
   * This currently fires onLoad immediately on font change because
   * there is no great way to detect font load.
   */
  onLoad?: (shape: TextShapeData) => void;
}

export default class TextShape extends React.Component<TextShapeProps> {
  textRef: any;

  textHeight = () => {
    return Math.ceil(this.textRef && this.textRef.height() || 0);
  }

  textWidth = () => {
    return Math.ceil(this.textRef && this.textRef.width() || 0);
  }

  componentDidMount() {
    const width = this.textWidth();
    this.props.onSelect(this.props.shape);
    if (!this.props.shape.width) {
      this.props.onUpdate(Object.assign({}, this.props.shape, {
        width,
      }));
    }
    // Fire immediately because font load is difficult to detect
    if (this.props.onLoad) { this.props.onLoad(this.props.shape); }
  }

  componentDidUpdate(prevProps: TextShapeProps) {
    const textChanged = prevProps.shape.text !== this.props.shape.text;
    const fontFamilyChanged = prevProps.shape.fontFamily !== this.props.shape.fontFamily;
    const heightChanged = prevProps.shape.height !== this.props.shape.height;

    const fontSize = computeFontSize(this.props.shape);
    const width = this.textWidth();
    const height = this.textHeight();

    if (heightChanged) {
      this.props.onUpdate(Object.assign({}, this.props.shape, {
        fontSize,
      }));
    }

    if (fontFamilyChanged) {
      this.props.onUpdate(Object.assign({}, this.props.shape, {
        width,
        fontSize,
      }));
    }

    if (textChanged) {
      this.props.onUpdate(Object.assign({}, this.props.shape, {
        width,
        height,
      }));
    }
  }

  render() {
    const {
      selected,
      draggable,
      resizeable,
      shape,
      mainPrintZone,
      guidelinePositions,
      scale,
      onDragStart,
      onDragMove,
      onDragEnd,
      onMouseOver,
      onMouseOut,
      onUpdate,
      onResizeStart,
      onResizeEnd,
      onDeleteShape,
      snapToGuidelines,
    } = this.props;

    const {
      x,
      y,
      id,
      text,
      fontSize,
      fontFamily,
      align,
      color,
      rotation,
    } = shape;

    // Konva's text.width() sometimes gives us unexpected values,
    // in order to be consistent with our codebase (ie. rotation, control box transformations),
    // we will only use text.width() to populate the text, and shape.width for future updates
    const width = this.props.shape.width || this.textWidth();
    const height = this.props.shape.height || this.textHeight();

    const textArray = (normalizeText(text)).split(NEW_LINE_CHAR);
    const newTextArray = [];
    // This is a workaround to add padding to text
    // for fonts that render outside the bounding box
    for (let _text of textArray) {
      _text = _text.trim();
      newTextArray.push(` ${_text} `);
    }

    const renderedText = newTextArray.join(NEW_LINE_CHAR);

    return (
      <Group
        name={id}
        x={x}
        y={y}
        draggable={draggable}
        onClick={(event) => {
          event.cancelBubble = true;
          this.props.onSelect(shape);
        }}
        onTap={(event) => {
          event.cancelBubble = true;
          this.props.onSelect(shape);
        }}
        onDragStart={() => onDragStart(shape)}
        onDragMove={(event) => {
          const mousePosition = {
            x: event.target.attrs.x + shape.width / 2,
            y: event.target.attrs.y + shape.height / 2,
          };
          const draggedShape = Object.assign({}, shape, {
            x: event.target.attrs.x,
            y: event.target.attrs.y
          });

          onDragMove(draggedShape, mousePosition);
        }}
        onDragEnd={(event) => {
          let newShape = Object.assign({}, shape, {
            x: event.target.attrs.x,
            y: event.target.attrs.y,
          });
          if (snapToGuidelines) {
            const mousePosition = {
              x: event.target.attrs.x + shape.width / 2,
              y: event.target.attrs.y + shape.height / 2,
            };
            const mainGuideline = createGuideline(mainPrintZone, guidelinePositions[0]);
            const snapData = snapToGuideline(newShape, mousePosition, mainGuideline);
            if (snapData.isSnappable) {
              newShape = Object.assign({}, shape, snapData.shape);
            }
          }
          onUpdate(newShape);
          onDragEnd(shape);
        }}
        onMouseOver={() => onMouseOver(shape)}
        onMouseOut={() => onMouseOut(shape)}
      >
        <Text
          x={width / 2}
          y={height / 2}
          offsetX={width / 2}
          offsetY={height / 2}
          rotation={rotation}
          ref={ref => (this.textRef = ref)}
          fontSize={fontSize}
          text={renderedText}
          fontFamily={fontFamily}
          align={align}
          lineHeight={LINE_HEIGHT}
          fill={color}
          wrap="none"
        />
        <ControlBox
          selected={selected ? shape : undefined}
          onUpdateShape={onUpdate}
          onResizeStart={onResizeStart}
          onResizeEnd={onResizeEnd}
          mainPrintZone={mainPrintZone}
          onDeleteShape={onDeleteShape}
          resizable={resizeable}
          scale={scale}
        />
      </Group>
    );

  }
}

          // dragBoundFunc={(pos: { x: number; y: number }) => {
          //   if (!width) return { x: pos.x, y: pos.y };
          //   let newX = Math.max((mainPrintZone.x * scale.x) + (width * scale.x / 2) - (rPosition.x - (x || 0)), pos.x);
          //   let newY = Math.max((mainPrintZone.y * scale.y) + (height * scale.y / 2) - (rPosition.y - (y || 0)), pos.y);

          //   newX = Math.min((mainPrintZone.x * scale.x) + (mainPrintZone.width * scale.x) - (width * scale.x / 2) + (rPosition.x - (x || 0)), newX);
          //   newY = Math.min((mainPrintZone.y * scale.y) + (mainPrintZone.height * scale.y) - (height * scale.y / 2) + (rPosition.y - (y || 0)), newY);
          //   return { x: newX, y: newY };
          // }}
