import * as React from 'react';
import { Group, Text, Rect, Line } from 'react-konva';

export interface PrintZoneProps {
  /** Print Zone name **/
  name: string;

  /** Flag to indicate it is the main print zone **/
  main: boolean;

  /** Position **/
  x: number;
  y: number;

  /** Dimensions **/
  width: number;
  height: number;

  /** Color hex */
  color?: string;
}

export const DEFAULT_PRINTZONE_PROPS = {
  color: '#B3B087',
  strokeWidth: 2,
  marginWidth: 4,
  fontSize: 16,
};

const PrintZone: React.FunctionComponent<PrintZoneProps> = (props) => {
  const { main, x, y, width, height, name, color = DEFAULT_PRINTZONE_PROPS.color } = props;

  const strokeWidth = DEFAULT_PRINTZONE_PROPS.strokeWidth;
  const marginWidth = DEFAULT_PRINTZONE_PROPS.marginWidth;
  const fontSize = DEFAULT_PRINTZONE_PROPS.fontSize;

  return (
    <Group>
      <Text
        x={x + (strokeWidth + marginWidth * 2)}
        y={y + height - (strokeWidth + fontSize + marginWidth)}
        text={name}
        fill={color}
        fontSize={fontSize}
      />
      <Rect x={x} y={y} width={width} height={height} stroke={color} strokeWidth={strokeWidth} />
      {main && (
        <>
          <Line
            points={[x + Math.floor(width / 2), y, x + Math.floor(width / 2), y + height]}
            stroke={color}
            strokeWidth={strokeWidth}
          />
          <Line
            points={[x, y + Math.floor(height / 2), x + width, y + Math.floor(height / 2)]}
            stroke={color}
            strokeWidth={strokeWidth}
          />
        </>
      )}
    </Group>
  );
};

export default PrintZone;
