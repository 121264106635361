import * as React from 'react';
import { Rect, Group, Line } from 'react-konva';

import { rotatedPosition, reversedRotatedPositions } from 'inkp-design-sdk/geometry';
import { Shape } from '../../../interfaces/Canvas';

interface Props {
  /** Shape selected */
  hovered?: Shape;
}

const HOVER_COLOR = '#FE9695';
const PADDING = 1;

export default class ControlBox extends React.Component<Props> {
  render() {
    const { hovered } = this.props;
    if (!(hovered && hovered.width && hovered.height)) return null;

    const rPosition = rotatedPosition(hovered);

    return (
      <Group name="hover-group">
        <Rect
          name="hover-border"
          x={rPosition.x - PADDING}
          y={rPosition.y - PADDING}
          width={rPosition.width + PADDING * 2}
          height={rPosition.height + PADDING * 2}
          stroke={HOVER_COLOR}
          stokeWidth={1}
          listening={false}
        />
      </Group>
    );
  }
}
