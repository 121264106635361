import React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { Font, FontCategory } from '../../../interfaces';
import FontList from './FontList';
import Categories from './Categories';
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_CATEGORY } from '../../../util/canvas';

export interface FontObject extends Font {
  active?: boolean;
}

interface Props {
  /**
   * currently selected category
   * @type string
   */
  activeCategory?: string;

  /**
   * currently selected FontFamily
   * @type string
   */
  activeFont?: string;

  /**
   * What to do with clicked font object
   * @type (fontObject: FontObject) => void;
   */
  onFontSelect: (fontObject: FontObject) => void;

  /**
   * What to do with clicked font object
   * @type (fontObject: FontObject) => void;
   */
  onCategorySelect: (categoryObject: FontCategory) => void;

  /**
   * action to change any state when category header is clicked
   * @type () => void;
   */
  toggleListOpen: () => void;

  /**
   * current state of category header
   * @type boolean
   */
  categoryListOpen: boolean;

  /**
   * Example text to display per FontListItem
   */
  exampleText?: string;

  /**
   * ClassNames
   */
  className?: string;
}

export const FONTS_BY_CATEGORIES_QUERY = gql`
  query FontsByCategories($categories: [String!]!) {
    fontsByCategories(categories: $categories) {
      name
      url
    }
  }
`;

export const FONT_CATEGORIES_QUERY = gql`
  query Categories {
    findCategories(fontNames: null)
  }
`;

export const FONTS_BY_NAMES_QUERY = gql`
  query FontsByName($fontNames: [String!]) {
    fontsByNames(fontNames: $fontNames) {
      name
      url
    }
  }
`;

export class FontCategoriesQuery extends Query<{ findCategories: string[] }, { data: string[] }> {};
export class FontsByCategoriesQuery extends Query<{ fontsByCategories: Font[] }, { categories: string[] }> {};
export class FontsByNamesQuery extends Query<{ fontsByNames: Font[] }, { fontNames: string[] }> {};

const FontSelector: React.FunctionComponent<Props> = (props) => {
  const {
    activeCategory = DEFAULT_FONT_CATEGORY.name,
    activeFont = DEFAULT_FONT_FAMILY,
    exampleText,
    onCategorySelect,
    onFontSelect,
    toggleListOpen,
    categoryListOpen,
    className,
  } = props;
  return (
    <div className={classnames(className, "h-full flex flex-col")}>
      <FontCategoriesQuery query={FONT_CATEGORIES_QUERY}>
        {({ data: categoryData = { findCategories: [] }, error, loading }) => {
          return (
            <FontsByCategoriesQuery query={FONTS_BY_CATEGORIES_QUERY} variables={{ categories: [activeCategory] }}>
              {({ data: fontData = { fontsByCategories: [] }, error: fontError, loading: fontLoading }) => {
                const { findCategories = [] } = categoryData;
                const { fontsByCategories = [] } = fontData;
                const categories = findCategories.map((name) => ({
                  name,
                  active: activeCategory === name,
                }));
                const fonts = fontsByCategories.map(({ name, ...rest }) => ({
                  name,
                  active: activeFont === name,
                  text: exampleText,
                  ...rest,
                }));
                return (
                  <>
                    <Categories
                      categories={categories}
                      onCategorySelect={onCategorySelect}
                      toggleListOpen={toggleListOpen}
                      listOpen={categoryListOpen}
                    />
                    <FontList fonts={fonts} onFontSelect={onFontSelect} />
                  </>
                );
              }}
            </FontsByCategoriesQuery>
          );
        }}
      </FontCategoriesQuery>
    </div>
  );
};

export default FontSelector;
