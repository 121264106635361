import * as React from 'react';
import { Group, Line } from 'react-konva';

import { PrintZoneProps } from '../';
import { createGuideline } from '../../../../util/canvas';
import { GuidelinePosition } from '../../../../interfaces/Canvas';

const DEFAULT_GUIDELINE_PROPS = {
  color: '#FE5454',
  strokeWidth: 3,
};

interface GuidelineProps extends Pick<PrintZoneProps, 'x' | 'y' | 'width' | 'height'> {
  color?: string;
  position: GuidelinePosition;
}

const Guideline: React.FunctionComponent<GuidelineProps> = ({ key, x, y, width, height, color = DEFAULT_GUIDELINE_PROPS.color, position = 'vertical-center' }) => {
  const guideline = createGuideline({ x, y, width, height }, position);
  return (
    <Group>
      <Line
        points={[
          x + guideline.start.x, guideline.start.y,
          x + guideline.end.x, guideline.end.y,
        ]}
        stroke={color}
        strokeWidth={DEFAULT_GUIDELINE_PROPS.strokeWidth}
      />
    </Group>
  );
}

export default Guideline;