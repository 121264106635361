import * as React from 'react';
import { Rect, Group, Line } from 'react-konva';

import { rotatedPosition, reversedRotatedPositions } from 'inkp-design-sdk/geometry';
import { Shape } from '../../../interfaces/Canvas';

interface Props {
  /** Shape selected */
  selected?: Shape;

  /** On Resize handler */
  onUpdateShape: (shape: Shape) => void;

  /** On Resize start */
  onResizeStart: (shape: Shape) => void;

  /** On Resize end */
  onResizeEnd: (shape: Shape) => void;

  /** delete item hanlder **/
  onDeleteShape: (shape: any) => void;

  /** Resize handle interactable **/
  resizable?: boolean;

  mainPrintZone: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  scale: {
    x: number;
    y: number;
  }
}

const CONTROL_COLOR = '#FE5454';
const PADDING = 1;
const ANCHOR_SIZE = 18;

const MIN_HEIGHT = 24;

const ICON_PADDING = 4;
const LINE_WIDTH = 2;
const RESIZE_LINE_LENGTH = 6;

const RESIZE_BOUND_PADDING = 24;

export default class ControlBox extends React.Component<Props> {
  render() {
    const { selected, mainPrintZone, scale } = this.props;
    if (!(selected && selected.width && selected.height)) return null;

    // override x, y to the relative pos to the containing group
    const rPosition = rotatedPosition({...selected, x: 0, y: 0});
    const absPosition = { ...rPosition, x: rPosition.x + selected.x, y: rPosition.y + selected.y };

    const deleteX = rPosition.x - ((ANCHOR_SIZE - PADDING) / scale.y);
    const deleteY = rPosition.y - ((ANCHOR_SIZE - PADDING) / scale.y);

    const resizeX = rPosition.x + rPosition.width + (PADDING / scale.y);
    const resizeY = rPosition.y + rPosition.height + (PADDING / scale.y);

    return (
      <Group name="control-group">
        <Rect
          name="control-border"
          x={rPosition.x - PADDING}
          y={rPosition.y - PADDING}
          width={rPosition.width + PADDING * 2}
          height={rPosition.height + PADDING * 2}
          stroke={CONTROL_COLOR}
          stokeWidth={1}
          listening={false}
        />
        <Group
          name="control-delete-group"
          x={deleteX}
          y={deleteY}
          width={ANCHOR_SIZE / scale.y}
          height={ANCHOR_SIZE / scale.y}
          onMouseEnter={() => {
            document.body.style.cursor = 'pointer';
          }}
          onMouseLeave={() => {
            document.body.style.cursor = 'default';
          }}
          onClick={() => this.props.onDeleteShape(selected)}
          onTap={() => this.props.onDeleteShape(selected)}
        >
          <Rect
            name="control-delete"
            width={ANCHOR_SIZE / scale.y}
            height={ANCHOR_SIZE / scale.y}
            fill={CONTROL_COLOR}
          />
          <Group name="control-delete-icon">
            <Line
              points={[ICON_PADDING / scale.y, ICON_PADDING / scale.y, (ANCHOR_SIZE - ICON_PADDING) / scale.y, (ANCHOR_SIZE - ICON_PADDING) / scale.y]}
              stroke="white"
              strokeWidth={LINE_WIDTH / scale.y}
            />
            <Line
              points={[ICON_PADDING / scale.y, (ANCHOR_SIZE - ICON_PADDING) / scale.y, (ANCHOR_SIZE - ICON_PADDING) / scale.y, (ICON_PADDING) / scale.y]}
              stroke="white"
              strokeWidth={LINE_WIDTH / scale.y}
            />
          </Group>
        </Group>
        <Group
          name="control-resize-group"
          x={resizeX}
          y={resizeY}
          width={ANCHOR_SIZE / scale.y}
          height={ANCHOR_SIZE / scale.y}
          draggable={this.props.resizable}
          onMouseEnter={() => {
            document.body.style.cursor = 'nwse-resize';
          }}
          onMouseLeave={() => {
            document.body.style.cursor = 'default';
          }}
          dragBoundFunc={(pos: { x: number; y: number }) => {
            // Only allow the handle in a line that keep aspect ratio 
            let newY = Math.max(pos.y, (mainPrintZone.y + absPosition.y + MIN_HEIGHT + PADDING) * scale.y);
            let newX = (newY - ((absPosition.y + mainPrintZone.y) * scale.y)) / (absPosition.height / absPosition.width) + ((absPosition.x + mainPrintZone.x) * scale.x);
            if (newX < 0 + RESIZE_BOUND_PADDING) {
              newX = 0 + RESIZE_BOUND_PADDING;
              newY = (absPosition.height / absPosition.width) * (newX - (absPosition.x + mainPrintZone.x) * scale.x) + (absPosition.y + mainPrintZone.y) * scale.y;
            }

            return { x: newX, y: newY };
          }}
          onDragStart={(e: any) => {
            this.props.onResizeStart(selected);
          }}
          onDragMove={(e: any) => {
            const _height = (e.target.attrs.y - PADDING) - rPosition.y;
            const { x, y, width, height } = reversedRotatedPositions(selected, {
              x: rPosition.x,
              y: rPosition.y,
              width: _height * (rPosition.width / rPosition.height),
              height: _height,
            })
            this.props.onUpdateShape(Object.assign({}, selected, {
              x: selected.x + x, // convert back to absolute pos
              y: selected.y + y, // convert back to absolute pos
              width,
              height,
            }));
          }}
          onDragEnd={(e) => {
            this.props.onResizeEnd(selected);
            e.cancelBubble = true;
          }}
        >
          <Rect
            name="control-resize"
            width={ANCHOR_SIZE / scale.y}
            height={ANCHOR_SIZE / scale.y}
            fill={CONTROL_COLOR}
          />
          <Group name="control-resize-icon">
            <Line
              points={[(ICON_PADDING + RESIZE_LINE_LENGTH) / scale.y, ICON_PADDING / scale.y, ICON_PADDING / scale.y, ICON_PADDING / scale.y, ICON_PADDING / scale.y, (ICON_PADDING + RESIZE_LINE_LENGTH) / scale.y]}
              stroke="white"
              strokeWidth={LINE_WIDTH / scale.y}
            />
            <Line
              points={[(ANCHOR_SIZE - ICON_PADDING) / scale.y, (ANCHOR_SIZE - ICON_PADDING - RESIZE_LINE_LENGTH) / scale.y, (ANCHOR_SIZE - ICON_PADDING) / scale.y, (ANCHOR_SIZE - ICON_PADDING) / scale.y, (ANCHOR_SIZE - ICON_PADDING - RESIZE_LINE_LENGTH) / scale.y, (ANCHOR_SIZE - ICON_PADDING) / scale.y]}
              stroke="white"
              strokeWidth={LINE_WIDTH / scale.y}
            />
          </Group>
        </Group>
      </Group>
    );
  }
}
